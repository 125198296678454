<template>
  <section>
    <el-container id="manage-container" v-if="isLogin">
      <transition name="el-fade-in">
        <el-aside class="nav-container" v-show="true" width="200px">
          <div class="nav-top">
            <div style="width: 100%;">
              <!-- <el-image class="logo-box" style="width:100%;height:80px;" fit="contain" 
              src="https://bagman.cdn.zd-aichong.com/budaige_logo.jpg"></el-image> -->
              <div style="width:75%;margin:0 auto;font-weight: bold;">蒲工联盟</div>
            </div>
          </div>
          <el-scrollbar class="scroll-bar">
            <menu-container :active_name="active_name" @active="handleMenuActive"></menu-container>
          </el-scrollbar>
        </el-aside>
      </transition>
      <el-container>
        <el-header class="header-container">
          <head-container
            :show="true"
            :title="title"
            :crumb="crumb"
            @active="handleHeadCrumbActive"
            @logOut="logOut"
          ></head-container>
        </el-header>
        <el-main class="main-container">
          <div id="app">
            <router-view></router-view>
          </div>
        </el-main>
        <el-footer style="height:30px;line-height:30px;text-align: center;font-size: 12px; color:gray;">Copyright &copy; 2022杭州众地智能科技服务有限公司出品</el-footer>
      </el-container>
    </el-container>
    <Login v-else @goHome="goHome"></Login>
  </section>
</template>

<script>
// @ is an alias to /src
import MenuContainer from "@/components/MenuContainer";
import HeadContainer from "@/components/HeadContainer";
import Login from "@/views/Login.vue";
import { checkToken } from "@/api/system";
import {ping} from "@/api/websocket/index"

export default {
  name: "App",
  components: {
    MenuContainer,
    HeadContainer,
    Login
  },
  data() {
    return {
      title: "",
      crumb: [],
      active_name: "",
      isLogin: false,

    };
  },
  created() {
  },
  methods: {
    
    handleMenuActive(e) {
      this.active_name = e.name;
      this.title = e.title;
      this.crumb = e.crumb;
    },
    handleHeadCrumbActive(e) {
      console.log(e);
      this.active_name = e;
    },
    goHome(isLogin) {
      this.isLogin = isLogin;
    },
    logOut() {
      this.isLogin = false;
    }
  },
  mounted() {
    let token = localStorage.getItem("token");
    if (token) {
      this.isLogin = true;
      checkToken({}).then((res) => {
        if (res.code === 200) {
          localStorage.setItem("userAccount", res.data.user.username);
          this.$store.commit("user/set", res.data.user);
          this.$store.commit("menu/set", res.data.menu);
          this.$store.commit("permission/set",res.data.permission)
          localStorage.setItem("token", res.data.token);
          this.isLogin = true;
        } else {
          this.isLogin = false;
          localStorage.removeItem("userAccount");
          localStorage.removeItem("token");
        }
      });
    }

  }
};
</script>

<style lang="scss">
@import "./static/default";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: auto;
  height: 100%;
}

div {
  box-sizing: border-box;
}

#manage-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(240, 242, 245, 1);
  overflow: auto;
}

.header-container {
  height: 82px !important;
  padding: 0 !important;
}

.main-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom:0px !important;
}

.nav-container {
  width: 208px !important;
  height: 100vh;
  overflow: hidden !important;
  background-color: white;
  box-shadow: 3px 0px 8px 0px rgba(0, 21, 41, 0.35);

  .nav-top {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 29px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-image {
      width: 60%;
    }
  }

  .scroll-bar {
    width: 100%;
    overflow-x: hidden;
    height: calc(100vh - 23px - 59px);
  }
}

.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.logo-box{
  img{
    border-radius: 10px;
  }
}
</style>
<style>
  .el_button{
    color:black;
  }
  .el-scrollbar__wrap{
    margin-bottom: 0 !important;
  }

  .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      width: 4px;height: 10px;
      position:absolute !important;
      z-index: 100;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 6px;
      height: 10px;
      background: rgba(0, 0, 0, 0.2);
      position:absolute !important;
      z-index: 100;
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      border-radius: 6px;
    }
</style>
