import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = `/system`

export function checkToken(params={}){
  return axios.request({
    url: `${url}/check/token`,
    method: "get",
    params,
  })
}

export function getAdmins(params = {}) {
  return axios.request({
    url: `${url}/admins/all`,
    method: "get",
    params,
  })
}

export function searchAdmins(params = {}) {
  return axios.request({
    url: `${url}/admins`,
    method: "get",
    params,
  })
}

export function addAdmin(data={}){
  return axios.request({
    url: `${url}/admin`,
    method: "post",
    data,
  })
}

export function getAdmin(id, params={}){
  return axios.request({
    url:`${url}/admin/${id}`,
    method: "get",
    params,
  })
}

export function updateAdmin(id, data={}){
  return axios.request({
    url:`${url}/admin/${id}`,
    method: "put",
    data,
  })
}

export function deleteAdmin(id, data={}){
  return axios.request({
    url:`${url}/admin/${id}`,
    method: "delete",
    data,
  })
}
//拉黑账号
export function blockAdmin(id, data={}){
  return axios.request({
    url:`${url}/admin/ban/${id}`,
    method: "put",
    data,
  })
}
//解封账号
export function unblockAdmin(id, data={}){
  return axios.request({
    url:`${url}/admin/unban/${id}`,
    method: "put",
    data,
  })
}
//修改密码
export function editPassword(data={}){
  return axios.request({
    url:`${url}/admin/password`,
    method: "put",
    data,
  })
}
//重置密码
export function resetPassword(id, data={}){
  return axios.request({
    url:`${url}/admin/reset/${id}`,
    method: "put",
    data,
  })
}


export function getPermissions(params = {}) {
  return axios.request({
    url: `${url}/permission/all`,
    method: "get",
    params,
  })
}

export function searchPermissions(params = {}) {
  return axios.request({
    url: `${url}/permissions`,
    method: "get",
    params,
  })
}

export function addPermission(data={}){
  return axios.request({
    url: `${url}/permission`,
    method: "post",
    data,
  })
}

export function getPermission(id, params={}){
  return axios.request({
    url:`${url}/permission/${id}`,
    method: "get",
    params,
  })
}

export function updatePermission(id, data={}){
  return axios.request({
    url:`${url}/permission/${id}`,
    method: "put",
    data,
  })
}

export function deletePermission(id, data={}){
  return axios.request({
    url:`${url}/permission/${id}`,
    method: "delete",
    data,
  })
}

// 获取菜单列表
export function getMenus(params={}) {
  return axios.request({
    url:`${url}/menus`,
    method: "get",
    params,
  })
}

export function addMenu(data={}){
  return axios.request({
    url: `${url}/menu`,
    method: "post",
    data,
  })
}

export function getMenu(id, params={}){
  return axios.request({
    url:`${url}/menu/${id}`,
    method: "get",
    params,
  })
}

export function updateMenu(id, data={}){
  return axios.request({
    url:`${url}/menu/${id}`,
    method: "put",
    data,
  })
}

export function deleteMenu(id, data={}){
  return axios.request({
    url:`${url}/menu/${id}`,
    method: "delete",
    data,
  })
}

export function getRoles(params = {}) {
  return axios.request({
    url: `${url}/roles/all`,
    method: "get",
    params,
  })
}

export function searchRoles(params = {}) {
  return axios.request({
    url: `${url}/roles`,
    method: "get",
    params,
  })
}

export function addRole(data={}){
  return axios.request({
    url: `${url}/role`,
    method: "post",
    data,
  })
}

export function getRole(id, params={}){
  return axios.request({
    url:`${url}/role/${id}`,
    method: "get",
    params,
  })
}

export function updateRole(id, data={}){
  return axios.request({
    url:`${url}/role/${id}`,
    method: "put",
    data,
  })
}

export function deleteRole(id, data={}){
  return axios.request({
    url:`${url}/role/${id}`,
    method: "delete",
    data,
  })
}


export function getTags(params = {}) {
  return axios.request({
    url: `${url}/tags/all`,
    method: "get",
    params,
  })
}

export function searchTags(params = {}) {
  return axios.request({
    url: `${url}/tags`,
    method: "get",
    params,
  })
}

export function addTag(data={}){
  return axios.request({
    url: `${url}/tag`,
    method: "post",
    data,
  })
}

export function getTag(id, params={}){
  return axios.request({
    url:`${url}/tag/${id}`,
    method: "get",
    params,
  })
}

export function updateTag(id, data={}){
  return axios.request({
    url:`${url}/tag/${id}`,
    method: "put",
    data,
  })
}

export function deleteTag(id, data={}){
  return axios.request({
    url:`${url}/tag/${id}`,
    method: "delete",
    data,
  })
}


export function getGroups(params = {}) {
  return axios.request({
    url: `${url}/groups/all`,
    method: "get",
    params,
  })
}

export function searchGroups(params = {}) {
  return axios.request({
    url: `${url}/groups`,
    method: "get",
    params,
  })
}

export function addGroup(data={}){
  return axios.request({
    url: `${url}/group`,
    method: "post",
    data,
  })
}

export function getGroup(id, params={}){
  return axios.request({
    url:`${url}/group/${id}`,
    method: "get",
    params,
  })
}

export function updateGroup(id, data={}){
  return axios.request({
    url:`${url}/group/${id}`,
    method: "put",
    data,
  })
}

export function deleteGroup(id, data={}){
  return axios.request({
    url:`${url}/group/${id}`,
    method: "delete",
    data,
  })
}

