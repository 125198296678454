
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';

    @Component
    export default class HeadContainer extends Vue {
        @Prop({default: false}) private show!: boolean;
        @Prop({default: ''}) private title!: string;
        @Prop({default: []}) private crumb!: string;
        public userName!: string;
        public userRole:string;
        public mineTitle = this.title
        public mineCrumb = this.crumb
        public mineShow: boolean = this.show

        @Watch('show', {immediate: true, deep: true})
        private onShowChange(newValue: boolean, oldValue: boolean) {
            this.mineShow = newValue
        }

        @Watch('title', {immediate: true, deep: true})
        private onTitleChange(newValue: string, oldValue: string) {
            this.mineTitle = newValue
            // this.$forceUpdate()
        }

        @Watch('crumb', {immediate: true, deep: true})
        private onCrumbChange(newValue: any, oldValue: any) {
            this.mineCrumb = newValue
            // this.$forceUpdate()
        }

        private handleCrumbClick(name: string) {
            // if (this.$router.currentRoute.name === name) {
            //     return false
            // }
            this.emitCrumbActive(name)
        }

        @Emit('active')
        private emitCrumbActive(name: string) {
            return name
        }
        // logOut(){}
        public handleCommand(command:any):void{
          if(command==='logOut'){
             this.$confirm('确认要退出登陆吗？') 
             .then(()=>{
               localStorage.removeItem('userAccount');
               localStorage.removeItem('token');
               localStorage.removeItem('currentMenu');
               this.logOut()
             })
            //  .catch(()=>{
            //  })
          }
          if(command==='person'){
            this.$router.push({name:'mine'}) 
            //  .catch(()=>{
            //  })
          }
        }

        @Emit('logOut')
        public logOut(){

        }

        get userData(){
          return this.$store.state.user.userData
        }

        @Watch('userData',{immediate: true, deep: true})
        private watchUserData(newValue: any, oldValue: any){
            this.userName = newValue.nickname
            this.userRole = newValue.role_name
            this.$forceUpdate()
        }

        public mounted(): void {
            this.$forceUpdate()
            this.userName = this.$store.state.user.userData.nickname
            this.userRole = this.$store.state.user.userData.group_name
        }

    }
