
import { __values } from "tslib";
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";

function broken(menu: object | undefined): any {
  return menu == undefined ? {} : menu;
}

@Component
export default class MenuContainer extends Vue {
  @Prop() private active_name!: string;
  private mine_active: string = this.active_name;
  public menu_list: Array<any> = [];
  public isCollapse:boolean = false;
  public defaultActive:string = ''
  public _this:Object

  @Emit("active")
  private emitActive(title: string, name: string, crumb: any): object {
    localStorage.setItem("currentMenu", name);
    return { title, name, crumb };
  }

  public handleOpen(self,father){
    this.$router.push({ path:  '/'+self.url},(error) => {console.log(error)});
    this.emitActive(self.menu_id, self.name, [
      father,self
    ]);
  }

  get menuData() {
    return this.$store.state.menu.menuArr;
  }

  @Watch("menuData", { immediate: true, deep: true })
  private watchMenuData(newValue: any, oldValue: any) {
    this.menu_list = this.$store.state.menu.menuArr;
    this.$forceUpdate();
    this.$nextTick(() => {
      let name: string = localStorage.getItem("currentMenu");
      this.$forceUpdate();
    });
  }

  get routePath(){
    return this.$route.path
  }

  @Watch("routePath", { immediate: true, deep: true })
  private wachRoutePath(newValue: string, oldValue: string) {
    let path = newValue.substring(1,newValue.length)
    this.menu_list.forEach((value,key)=>{
      if(value.children.length>0){
        value.children.forEach(v=>{
          if(v.url===path) this.defaultActive = v.menu_id
          return;
        })
      }else{
        if(value.url===path) this.defaultActive = value.menu_id
        return;
      }
    })
  }

  public mounted(): void {
    // this.menu_list = broken(this.$router.options.routes);
    let path = this.$route.path.substring(1, this.$route.path.length)
    this.menu_list = this.$store.state.menu.menuArr;
    this.$nextTick(() => {
      this.$forceUpdate();
    });
  }
}
