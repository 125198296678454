import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = `/index`

export function index(params = {}) {
  return axios.request({
    url: `${url}/index`,
    method: "get",
    params,
  })
}

export function getInited(params = {}) {
  return axios.request({
    url: `${url}/inited`,
    method: "get",
    params,
  })
}

export function login(data = {}) {
  return axios.request({
    url: `${url}/login`,
    method: "post",
    data: data,
  })
}

export function register(data = {}) {
  console.log(data)
  return axios.request({
    url: `${url}/register`,
    method:`post`,
    data:data
  })
}
export function getProvince(data = {}){
  return axios.request({
    url: `${url}/../provinces`,
    method: 'get',
    params: data
  })
}

export function getCity(id,data = {}){
  return axios.request({
    url: `${url}/../cities/${id}`,
    method: 'get',
    params: data
  })
}

export function getArea(id,data = {}){
  return axios.request({
    url: `${url}/../areas/${id}`,
    method: 'get',
    params: data
  })
}

export function getQiniuToken(data){
  return axios.request({
    url: `${url}/../cdn/token`,
    method: 'post',
    data: data
  })
}

export function tencentAddressKeyword(params={}){
  return axios.request({
    url: `${url}/../address/keyword`,
    method: 'get',
    params: params
  }) 
}

export function areaDetailCode(params={}){
  return axios.request({
    url: `${url}/../area/detail`,
    method: 'get',
    params: params
  }) 
}

